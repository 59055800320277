import { SmartAssemblies, SmartAssembly } from "@eveworld/types";
import { SmartAssemblyProperties } from "@eveworld/types/types";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import memoize from "memoizee";
// import dotenv from "dotenv";
// dotenv.config();

export default class GatewayHTTPService {
  private static instance: GatewayHTTPService;
  private isInitialized: boolean = false;
  private client?: AxiosInstance;
  private defaultBaseURL: string =
    "https://blockchain-gateway-stillness.live.tech.evefrontier.com";

  private constructor() {
    this.initialize();
  }

  public static getInstance(): GatewayHTTPService {
    if (!GatewayHTTPService.instance) {
      GatewayHTTPService.instance = new GatewayHTTPService();
    }
    return GatewayHTTPService.instance;
  }

  private initialize = () => {
    if (this.isInitialized && this.client) return;
    this.isInitialized = true;
    console.warn(
      "Initializing GatewayHTTPService with baseUrl: ",
      this.defaultBaseURL
    );
    console.log(import.meta.env.VITE_GATEWAY_HTTP);
    this.client = axios.create({
      baseURL: import.meta.env.VITE_GATEWAY_HTTP || this.defaultBaseURL,
      timeout: 5000,
    });
  };

  public getClient = (): AxiosInstance => {
    if (!this.isInitialized || !this.client) {
      this.initialize();
    }
    return this.client as AxiosInstance;
  };

  private memoizedTypeFetch = memoize(
    async (typeId: bigint) => {
      return await this.getClient().get(`/types/${typeId.toString()}`);
    },
    {
      maxAge: 10 * 1000,
      promise: true,
    }
  );

  public getTypeMetadata = async (typeId: bigint) => {
    if (!this.isInitialized || !this.client) {
      this.initialize();
    }
    return await this.memoizedTypeFetch(typeId);
  };

  private memoizedSmartAssembliesFetch = memoize(
    async () => {
      return await this.getClient().get(`/smartassemblies`);
    },
    {
      maxAge: 10 * 1000,
      promise: true,
    }
  );

  public getSmartAssemblies = async (): Promise<
    AxiosResponse<SmartAssemblyProperties<SmartAssemblies>[]>
  > => {
    if (!this.isInitialized || !this.client) {
      this.initialize();
    }
    return (await this.memoizedSmartAssembliesFetch()) as any;
  };

  public getSSUsInSystems = async (systems: string[]) => {
    const systemsMap: Map<number, number> = new Map();
    systems.forEach((system) => {
      systemsMap.set(
        parseInt(system),
        (systemsMap.get(parseInt(system)) || 0) + 1
      );
    });
    return await this.getSmartAssemblies().then((res) => {
      const d = res.data.filter((sa: any) => {
        return (
          systemsMap.has(sa.solarSystem?.solarSystemId) &&
          sa.assemblyType === "SmartStorageUnit"
        );
      });
      // console.log("d", d);
      return d;
    });
  };

  public getOnlinedSmartAssembliesCountInSystems = async (
    systems: string[]
  ) => {
    return await this.getSmartAssemblies().then((res) => {
      const resMap = res.data.reduce<Record<string, number>>(
        (acc: Record<string, number>, sa) => {
          if (!sa.isOnline) return acc;
          const systemId =
            sa.solarSystem?.solarSystemId &&
            sa.solarSystem?.solarSystemId.toString();
          if (!acc[systemId]) {
            acc[systemId] = 0;
          }
          acc[systemId] += 1;
          return acc;
        },
        {} as Record<string, number>
      );
      // console.log("resMap", resMap, systems);
      const z = systems.reduce<Record<string, number>>(
        (acc: Record<string, number>, solarSystemId: string, idx: number) => {
          acc[solarSystemId] = resMap[solarSystemId] || 0;
          return acc;
        },
        {} as Record<string, number>
      );
      // console.log("ALL assemblies", res.data.length);
      return z;
    });
  };
  public getOnlinedSmartGatesCountInSystems = async (systems: string[]) => {
    return await this.getSmartAssemblies().then((res) => {
      const resMap = res.data.reduce<Record<string, number>>(
        (acc: Record<string, number>, sa) => {
          if (!sa.isOnline || sa.assemblyType !== "SmartGate") return acc;
          const systemId =
            sa.solarSystem?.solarSystemId &&
            sa.solarSystem?.solarSystemId.toString();
          if (!acc[systemId]) {
            acc[systemId] = 0;
          }
          acc[systemId] += 1;
          return acc;
        },
        {} as Record<string, number>
      );
      console.log("ALL smart gates", resMap);
      return resMap
    });
  }
}
