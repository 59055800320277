import { LIGHT_YEAR_IN_METERS } from "@/constants";
import { MapDisplayType } from "./reducer";

const DEFAULT_MAP_SETTINGS = {
  nodeRadius : 0.8,
}

export const SETTINGS = {
  labelPosition: [0.1, 0.1, 0.025] as [number, number, number],
  nodeRadius: DEFAULT_MAP_SETTINGS.nodeRadius,
  linkWidth: 0.1,
  highlightedNodeRadius: 5,
  defaultSelectedNodeRadiusInMeters: 500 * LIGHT_YEAR_IN_METERS,
};

export const COLOR_THEME = {
  baseSystemColor: 0xadd8e6,
  baseSystemOpacity: 0.7,

  selectedSystemColor: 0x94e5ff,
  selectedSystemOpacity: 1,

  selectedSystemHaloColor: 0xadd8e6,
  selectedSystemHaloOpacity: 0.25,

  selectedDestinationSystemColor: 0xe7071b,
  selectedDestinationSystemOpacity: 1,

  selectedDestinationSystemHaloColor: 0xee515f,
  selectedDestinationSystemHaloOpacity: 0.2,

  selectedConstellationColor: 0xd9a759,
  selectedConstellationOpacity: 1,

  npcStationColor: 0xf7a768,

  linkColor: 0x808080,
  linkOpacity: 0.5,
};

export const mapDisplayTypeKey: Record<MapDisplayType, string> = {
  [MapDisplayType.REGION]: "regionID",
  [MapDisplayType.NPC_STATION]: "solarSystemId",
  [MapDisplayType.ACTIVE_ASSEMBLIES_COUNT]: "solarSystemId",
  [MapDisplayType.SMART_GATES_COUNT]: "solarSystemId",
};

export const mapDisplayTypeLabel: Record<MapDisplayType, string> = {
  [MapDisplayType.REGION]: "Regions",
  [MapDisplayType.NPC_STATION]: "NPC Stations",
  [MapDisplayType.ACTIVE_ASSEMBLIES_COUNT]: "Active Assemblies",
  [MapDisplayType.SMART_GATES_COUNT]: "Active Smart Gates",
};